import React, { forwardRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Tooltip } from '@getvim/atomic-ui';

import './styles.less';

export enum DescriptionPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface SelectTypeaheadProps {
  description: string;
  labelKey: string;
  filterBy?: string[];
  disableFilter?: boolean;
  className: string;
  options: any[];
  onInputChange: (searchTerm: string) => void;
  renderMenuItemChildren?(value: string, code: string, isLoadingStatus?: boolean): any;
  ref?: any;
  error: any;
  submitted?: boolean;
  placeholder?: string;
  disabled?: boolean;
  tooltipText?: string;
  descriptionPosition?: DescriptionPosition;
  isLoadingFlag?: boolean;
}

export const SelectTypeahead = forwardRef((props: SelectTypeaheadProps, ref: any) => {
  const {
    description,
    labelKey,
    filterBy,
    disableFilter = false,
    className,
    error,
    submitted,
    placeholder,
    disabled,
    tooltipText,
    descriptionPosition = DescriptionPosition.BOTTOM,
    isLoadingFlag,
  } = props;

  return (
    <div id={labelKey} className={`${className} v2-input`}>
      <div className={`${error && submitted && 'has-error'} select-wrapper`}>
        <Tooltip
          hideTooltip={!disabled || !tooltipText}
          tooltipContent={tooltipText}
          portal={false}
        >
          <div className="select">
            {descriptionPosition == DescriptionPosition.TOP && (
              <span className="typeahead-select-label">{description}</span>
            )}
            <Typeahead
              {...props}
              ref={ref}
              id="select-input"
              placeholder={placeholder}
              filterBy={disableFilter ? () => true : filterBy || [`${labelKey}`]}
              renderMenuItemChildren={(value) => {
                if (!props.renderMenuItemChildren) return;
                return props.renderMenuItemChildren(value[labelKey], value, isLoadingFlag);
              }}
            />

            {descriptionPosition == DescriptionPosition.BOTTOM && (
              <span className="typeahead-select-label">{description}</span>
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
});
